import env from '@/config/environment';
import { tz, TZDate } from '@date-fns/tz';
import { createContext, useContext } from 'react';

const TimezoneContext = createContext<string | null>(null);

function getTimezoneString() {
  if (env.isBrowser) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return 'America/Denver';
}

export default function Timezone({ children }: { children: React.ReactNode }) {
  const timezone = getTimezoneString();

  if (env.isBrowser) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    document.cookie = `timezone=${encodeURIComponent(timezone)}; path=/`;
  }

  return <TimezoneContext value={timezone}>{children}</TimezoneContext>;
}

export function useTimezone() {
  const timezoneString = useContext(TimezoneContext);
  if (!timezoneString) throw new Error('Timezone context not found');
  return [
    tz(timezoneString),
    timezoneString,
    () => TZDate.tz(timezoneString),
  ] as [
    tz: ReturnType<typeof tz>,
    timezoneString: string,
    timezoneNow: () => TZDate,
  ];
}
