'use client';

import { atom, Getter, PrimitiveAtom, useSetAtom, WritableAtom } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useHydrateAtoms } from 'jotai/utils';
import { useEffect } from 'react';

const unhydrated = Symbol('unhydrated');
/**
 * Create an atom that must be hydrated with data before it can be read. Useful
 * for atoms holding server data. Eliminates the need for initial data / null
 * checks.
 */
export function hydratedAtom<T>(name: string) {
  if (!name) {
    throw new Error('Hydrated atom must have a name');
  }
  const sourceAtom = atom<T | typeof unhydrated>(unhydrated);
  const wrapped = atom(
    (get) => {
      const source = get(sourceAtom);
      if (source === unhydrated) {
        throw new Error(`${name} atom was not hydrated`);
      }
      return source;
    },
    (_, set, value: T) => {
      set(sourceAtom, value);
    }
  );
  wrapped.debugLabel = name;
  return wrapped;
}

/**
 * Creates an atom that reads directly from a source atom but splits writes
 * between the source atom and a callback function.
 */
export function teeAtom<Value, Args extends unknown[]>(
  sourceAtom: WritableAtom<Value, Args, void>,
  onWrite: (value: Value, get: Getter, oldValue: Value) => void
): WritableAtom<Value, Args, void> {
  return atom(
    (get) => get(sourceAtom),
    (get, set, ...args: Args) => {
      const oldValue = get(sourceAtom);
      set(sourceAtom, ...args);
      const newValue = get(sourceAtom);
      onWrite(newValue, get, oldValue);
    }
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSyncAtoms(atoms: [PrimitiveAtom<any>, any][]) {
  useHydrateAtoms(atoms);
  for (const [atom, value] of atoms) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const setter = useSetAtom(atom);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      setter(value);
    }, [setter, value]);
  }
}

export function HydrateAtoms(props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  atoms: [PrimitiveAtom<any>, any][];
}) {
  useHydrateAtoms(props.atoms);
  return null;
}

export function SyncAtoms(props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  atoms: [PrimitiveAtom<any>, any][];
}) {
  useSyncAtoms(props.atoms);
  return null;
}

export function ScopeProviderWithInitial(props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  atoms: [PrimitiveAtom<any>, any][];
  children: React.ReactNode;
}) {
  return (
    <ScopeProvider atoms={props.atoms.map(([atom]) => atom)}>
      <HydrateAtoms atoms={props.atoms} />
      {props.children}
    </ScopeProvider>
  );
}
