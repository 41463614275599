import analytics from './analytics';
import environment from './environment';
import files from './files';
import llm from './llm';
import logging from './logging';
import mocks from './mocks';

const config = {
  analytics,
  environment,
  files,
  llm,
  logging,
  mocks,
};

export default config;
