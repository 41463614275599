'use client';

import { AnimatePresence, motion } from 'motion/react';
import { ArrowUpRight } from 'lucide-react';
import cn from 'mxcn';
import { RefObject, useRef, useState } from 'react';

export default function ProgramStyleCard({
  label,
  gridRef,
  className,
}: {
  label: string;
  gridRef: RefObject<HTMLDivElement | null>;
  className?: string;
}) {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const gridRect = gridRef.current?.getBoundingClientRect();
  const containerRect = containerRef.current?.getBoundingClientRect();
  const destRect = gridRect &&
    containerRect && {
      left: gridRect.left - containerRect.left,
      top: gridRect.top - containerRect.top,
      width: gridRect.width,
      height: gridRect.height,
    };

  return (
    <div
      ref={containerRef}
      className={cn('relative flex flex-auto hover:z-10', expanded && 'z-10')}
    >
      <motion.button
        animate={
          expanded && destRect
            ? {
                left: destRect?.left + 16,
                top: destRect?.top + 16,
                width: destRect?.width - 32,
                height: destRect?.height - 32,
              }
            : {
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
              }
        }
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 7,
          mass: 0.2,
        }}
        className={cn(
          'absolute flex-auto inset-0 text-xl md:text-lg lg:text-xl flex border border-contrast-200 rounded-xl group overflow-hidden text-left',
          expanded && 'shadow-lg',
          className
        )}
        onClick={() => setExpanded((s) => !s)}
      >
        <video
          autoPlay
          loop
          muted
          src="/assets/swirl.mp4"
          className={cn(
            'absolute inset-0 size-full opacity-0 transition object-cover',
            expanded ? 'opacity-100' : 'group-hover:opacity-100'
          )}
        />
        <div
          className={cn(
            'absolute inset-1 stack p-4 flex-auto bg-white text-contrast-600 rounded-lg opacity-100 transition duration-100 group-hover:delay-100',
            expanded ? 'opacity-0' : 'group-hover:opacity-0'
          )}
        >
          <div className="self-end">
            <ArrowUpRight size={24} className="" />
          </div>

          <div className="row flex-auto items-end font-bold">{label}</div>
        </div>
        <div
          className={cn(
            'absolute inset-1 stack p-4 flex-auto bg-white text-black opacity-0 rounded-lg mix-blend-screen transition duration-100 delay-100 group-hover:delay-0',
            expanded ? 'opacity-100' : 'group-hover:opacity-100'
          )}
        >
          <div className="self-end">
            <ArrowUpRight size={24} className="" />
          </div>
          <div className="row flex-auto items-end font-bold">{label}</div>
        </div>
        <AnimatePresence>
          {expanded && (
            <motion.div
              animate={{ opacity: 1, x: 0 }}
              initial={{ opacity: 0, x: 20 }}
              exit={{
                opacity: 0,
                x: 20,
                transition: { duration: 0.1, delay: 0 },
              }}
              transition={{ duration: 0.2, delay: 0.1 }}
              className="absolute size-full inset-0 px-4 py-12 rounded-xl overflow-hidden"
            >
              <div className="stack-2 flex-auto w-1/2 shrink">
                <div className="font-semibold text-2xl">
                  The best of both worlds
                </div>
                <div className="text-contrast-500">
                  OpenEd lets you get the flexibility and personalization of
                  homeschooling, with the resources and support of traditional
                  schools.
                </div>
              </div>
              <div className="absolute inset-y-3 right-3 w-1/3 overflow-hidden rounded-r-xl">
                <video
                  src="/assets/card-detail.mp4"
                  autoPlay
                  loop
                  muted
                  className="absolute inset-x-0 top-1/2 -translate-y-1/2 object-cover"
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.button>
    </div>
  );
}
