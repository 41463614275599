'use client';
import { GlobalProgressBar } from '@/components/ui/global-progress';
import { Toaster } from '@/components/ui/toaster';
import config from '@/config';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { type ReactNode } from 'react';
import GlobalJotaiProvider, { HydrationData } from './jotai';
import Timezone from './timezone';
import { TooltipProvider } from '@/components/ui/tooltip';

if (typeof window !== 'undefined' && config.analytics.posthog.enabled) {
  posthog.init(config.analytics.posthog.key, {
    api_host: config.analytics.posthog.host,
  });
}

export default function Providers({
  children,
  ...hydrationData
}: HydrationData & { children: ReactNode }) {
  return (
    <>
      <PostHogProvider client={posthog}>
        <GlobalJotaiProvider {...hydrationData}>
          <GlobalProgressBar>
            <Timezone>
              <TooltipProvider>{children}</TooltipProvider>
              <Toaster />
            </Timezone>
          </GlobalProgressBar>
        </GlobalJotaiProvider>
      </PostHogProvider>
    </>
  );
}
