'use client';

import { ActionError, ActionResult } from '@/lib/action';
import { hydratedAtom } from '@/lib/atoms';
import { atom, PrimitiveAtom, useAtomValue, useSetAtom } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useHydrateAtoms, useAtomCallback } from 'jotai/utils';
import { FormEvent, useCallback, useId } from 'react';
import { FieldState } from './field';
import { useRouter } from 'next/navigation';
import cn from 'mxcn';
import { Loader2 } from 'lucide-react';

export type FormProps<Values extends Record<string, unknown>, Result> = Omit<
  React.ComponentPropsWithRef<'form'>,
  'onSubmit'
> & {
  /**
   * The function to call when the form is submitted.
   */
  onSubmit?: (values: Values) => Promise<ActionResult<Result>>;
  /**
   * The function to call when the form is successfully submitted.
   */
  onSuccess?: string | ((data: Result) => void);
  /**
   * The function to call when the form is submitted and there are errors.
   */
  onError?: (errors: ActionError[]) => void;
  /**
   * Additional values to include when invoking the `onSubmit` function that
   * will override any values in the form. Useful to set fixed values, i.e. the
   * id of the object that the form updates.
   */
  args?: Partial<Values>;
  /**
   * If true, the form will automatically submit when the user changes a field.
   */
  autosave?: boolean;
  /**
   * The minimum number of milliseconds the onSubmit action should take. This is
   * useful if you want to show a loading indicator for a minimum amount of time.
   */
  minDuration?: number;
  /**
   * If true, the form-wide errors will not be rendered automatically. You must
   * use the `FormErrors` component yourself somewhere in the form.
   */
  manuallyRenderFormErrors?: boolean;

  children: React.ReactNode;
};

/**
 * A symbol used to indicate that the form has not been submitted yet.
 */
const UNSUBMITTED = Symbol.for('form.unsubmitted');

/**
 * An atom-of-atoms holding the current, in-memory state of all fields in the
 * form, keyed by field ID.
 */
export const fieldAtomsAtom = atom<
  Record<string, PrimitiveAtom<FieldState<unknown>>>
>({});

/**
 * Is the form currently submitting?
 */
export const isSubmittingAtom = atom(false);

/**
 * A combined atom to hold form state including ID, result, and errors.
 */
export const formStateAtom = hydratedAtom<{
  id: string;
  result: ActionResult<unknown> | typeof UNSUBMITTED;
  submissionErrors: ActionError[];
  onBlur: () => void;
}>('formState');

/**
 * An atom to hold the list of uploads in progress. The parent form will
 * wait for all uploads to complete before submitting.
 */
export const uploadsAtom = atom<Promise<unknown>[]>([]);

export default function Form<Values extends Record<string, unknown>, Result>(
  props: FormProps<Values, Result>
) {
  return (
    <ScopeProvider
      atoms={[formStateAtom, fieldAtomsAtom, uploadsAtom, isSubmittingAtom]}
    >
      <FormInner {...props} />
    </ScopeProvider>
  );
}

// This inner component is necessary to ensure we have the scoped provider setup
// above so atoms are scoped to the form.
function FormInner<Values extends Record<string, unknown>, Result>({
  onSubmit,
  onError,
  onSuccess,
  autosave,
  args,
  minDuration,
  children,
  ...props
}: FormProps<Values, Result>) {
  const router = useRouter();
  const id = useId();
  const setIsSubmitting = useSetAtom(isSubmittingAtom);

  minDuration ??= 500;

  const handleSubmit = useAtomCallback(async (get, set, e?: FormEvent) => {
    e?.preventDefault();
    if (!onSubmit) return;
    setIsSubmitting(true);
    // Collect the form field values
    const fieldAtoms = get(fieldAtomsAtom);
    const fieldStates = Object.values(fieldAtoms).map(get);

    // Set all fields as blurred
    Object.values(fieldAtoms).forEach((fieldAtom) => {
      set(fieldAtom, { ...get(fieldAtom), blurred: true });
    });

    if (fieldStates.some((field) => field.localErrors.length > 0)) {
      setIsSubmitting(false);
      return;
    }
    const values = {
      ...Object.fromEntries(
        fieldStates.map((field) => [field.name, field.value])
      ),
      ...args,
    } as Values;

    // Wait for all uploads to complete
    await Promise.all(get(uploadsAtom));
    set(uploadsAtom, []);

    // Submit the form
    const [result] = await Promise.all([
      onSubmit(values),
      new Promise((resolve) => setTimeout(resolve, minDuration)),
    ]);

    // Callbacks
    if (result.errors) {
      onError?.(result.errors);
    } else {
      if (typeof onSuccess === 'string') {
        router.push(onSuccess);
      } else {
        onSuccess?.(result.data!);
      }
    }

    // Update the form state
    set(formStateAtom, {
      ...get(formStateAtom),
      result,
      submissionErrors: result.errors ?? [],
    });
    setIsSubmitting(false);
  });

  const onBlur = useCallback(() => {
    if (autosave) {
      handleSubmit();
    }
  }, [autosave, handleSubmit]);

  useHydrateAtoms([
    [formStateAtom, { id, result: UNSUBMITTED, submissionErrors: [], onBlur }],
  ]);

  return (
    <form {...props} onSubmit={handleSubmit} id={id}>
      {!props.manuallyRenderFormErrors && <FormErrors />}
      {children}
    </form>
  );
}

export function FormErrors({ className }: { className?: string }) {
  const formState = useAtomValue(formStateAtom);
  const errors = formState.submissionErrors.filter((e) => !e.path);
  if (errors.length === 0) return null;
  return (
    <ul
      role="alert"
      className={cn(
        'bg-amber-300 text-contrast-800 p-2 rounded text-sm leading-snug',
        errors.length > 1 ? 'list-disc list-inside' : '',
        className
      )}
    >
      {errors.map((e, i) => (
        <li key={i}>{e.message}</li>
      ))}
    </ul>
  );
}

export function FormSubmittingIndicator(props: {
  className?: string;
  children?: React.ReactNode;
}) {
  const isSubmitting = useAtomValue(isSubmittingAtom);
  if (!isSubmitting) return null;
  return (
    props.children ?? (
      <Loader2
        role="status"
        className={cn('size-[1em] animate-spin', props.className)}
        aria-label="Submitting ..."
      />
    )
  );
}
